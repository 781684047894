exports.components = {
  "component---src-components-blog-blog-post-js": () => import("./../../../src/components/Blog/BlogPost.js" /* webpackChunkName: "component---src-components-blog-blog-post-js" */),
  "component---src-pages-2-stegs-metoden-js": () => import("./../../../src/pages/2-stegs-metoden.js" /* webpackChunkName: "component---src-pages-2-stegs-metoden-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-affiliate-disclosure-js": () => import("./../../../src/pages/affiliate-disclosure.js" /* webpackChunkName: "component---src-pages-affiliate-disclosure-js" */),
  "component---src-pages-blogg-js": () => import("./../../../src/pages/blogg.js" /* webpackChunkName: "component---src-pages-blogg-js" */),
  "component---src-pages-budget-js": () => import("./../../../src/pages/budget.js" /* webpackChunkName: "component---src-pages-budget-js" */),
  "component---src-pages-eisenhower-matrisen-js": () => import("./../../../src/pages/eisenhower-matrisen.js" /* webpackChunkName: "component---src-pages-eisenhower-matrisen-js" */),
  "component---src-pages-feynman-tekniken-js": () => import("./../../../src/pages/feynman-tekniken.js" /* webpackChunkName: "component---src-pages-feynman-tekniken-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-karriar-js": () => import("./../../../src/pages/karriar.js" /* webpackChunkName: "component---src-pages-karriar-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-om-js": () => import("./../../../src/pages/om.js" /* webpackChunkName: "component---src-pages-om-js" */),
  "component---src-pages-paretos-princip-js": () => import("./../../../src/pages/paretos-princip.js" /* webpackChunkName: "component---src-pages-paretos-princip-js" */),
  "component---src-pages-privacypolicy-js": () => import("./../../../src/pages/privacypolicy.js" /* webpackChunkName: "component---src-pages-privacypolicy-js" */),
  "component---src-pages-resurser-js": () => import("./../../../src/pages/resurser.js" /* webpackChunkName: "component---src-pages-resurser-js" */),
  "component---src-pages-spaced-repetition-js": () => import("./../../../src/pages/spaced-repetition.js" /* webpackChunkName: "component---src-pages-spaced-repetition-js" */),
  "component---src-pages-studentliv-js": () => import("./../../../src/pages/studentliv.js" /* webpackChunkName: "component---src-pages-studentliv-js" */),
  "component---src-pages-studera-js": () => import("./../../../src/pages/studera.js" /* webpackChunkName: "component---src-pages-studera-js" */),
  "component---src-pages-studieteknik-js": () => import("./../../../src/pages/studieteknik.js" /* webpackChunkName: "component---src-pages-studieteknik-js" */),
  "component---src-pages-tankekartor-js": () => import("./../../../src/pages/tankekartor.js" /* webpackChunkName: "component---src-pages-tankekartor-js" */),
  "component---src-pages-timeboxing-js": () => import("./../../../src/pages/timeboxing.js" /* webpackChunkName: "component---src-pages-timeboxing-js" */),
  "component---src-pages-todolist-js": () => import("./../../../src/pages/todolist.js" /* webpackChunkName: "component---src-pages-todolist-js" */)
}

